import React, { useEffect } from "react";
import { Link, graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import postcss from "postcss";

const Thoughts = ({ data }) => {
  useEffect(() => {
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;

    if (width > 1080) window.addEventListener("scroll", scrollListener);

    return () => {
      window.removeEventListener("scroll", scrollListener);
    };
  });

  const scrollListener = e => {
    const scrollTop =
      document.body.scrollTop || document.documentElement.scrollTop;
    if (scrollTop > 50) {
      document.querySelector("#title").style.fontSize = "1.875rem";
      document.querySelector(".site_header").style.paddingTop = "0";
      document.querySelector("nav").style.marginTop = "0.5rem";
    } else {
      document.querySelector("#title").style.fontSize = "3.5rem";
      document.querySelector(".site_header").style.paddingTop = "1rem";
      document.querySelector("nav").style.marginTop = "1.5rem";
    }
  };

  const allPosts = data.allMarkdownRemark.nodes;

  return (
    <Layout>
      <SEO title="Thoughts" />
      <section id="thoughts">
        {allPosts.map(post => {
          if (!post.frontmatter.hide)
            return (
              <article className="mb-10">
                <Link to={post.fields.slug} key={post.id}>
                  <div className="thoughts_title">
                    <span className="thoughts_date">
                      {post.frontmatter.date}
                    </span>
                    <h2>{post.frontmatter.title}</h2>
                  </div>
                  <p>{post.frontmatter.description}</p>
                </Link>
              </article>
            );
          else return null;
        })}
      </section>
    </Layout>
  );
};

export default Thoughts;

export const pageQuery = graphql`
  query AllBlogPosts {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        frontmatter {
          description
          title
          date(formatString: "MMMM DD, YYYY")
          hide
        }
        id
        fields {
          slug
        }
      }
    }
  }
`;
